<template>
	<article class="course__block text-block">
		<p v-for="paragraph in paragraphs" :key="paragraph.key" v-html="doMark(paragraph.content)"></p>
	</article>
</template>

<script>
import markdownIt from 'markdown-it';
const md = markdownIt().use(require("markdown-it-multimd-table"))

export default {
	name: "StudentBlockTextBlock",
	props: {
		paragraphs: {
			type: Array,
			require: true
		}
	},
	methods: {
		doMark(e) {
			let content = md.render(e)
			return content
		},
	}
};
</script>

<style lang="scss" scoped></style>
